import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, useToast, TabIndicator
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import StepsComponent from "./stepsComponent";
import { BsCodeSlash } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight, AiFillDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { CodeEditor } from "../code/codeEditor";
import GetUserConfirm from "../confirm";
import AddOutcome from "./addOutcome";
import { FaCheck } from "react-icons/fa";
import { GiConsoleController } from "react-icons/gi";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
interface IWorkflow {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
    cb: Function
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
interface StepsProps {
    timeprofiles: Timeprofiles[],
    step: IWorkflowStep,
    workflow_id: string,
    cb: Function,
    refreshEventsFunction: Function,
}
interface Event {
    name: string,
    id: string,
}
interface StepOutcomeProps {
    stepId: string,
    cb: Function,
}
interface IOutcome {
    id: string,
    friendly_id: string,
    name: string,
    description: string,
}
interface IStepOutcomes {
    definitionId: string,
    id: string,
    name: string,
    friendly_id: string,
    is_completion_outcome: boolean,
    is_quick_outcome: boolean,
    is_reschedule_outcome: boolean,
    outcomeDefinition: string | null,
    outcomeDefinitionId: string,
    step: string | null,
    stepId: string,
    workflow: string | null
}
interface IStepOutcomeWithName {
    outcomeDefinitionId: string,
    name: string,
    friendly_id: string,
}

export default function ViewOutcomes(props: StepOutcomeProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const CacheContext = useCache();
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPagesFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [selectedEvent, setSelectedEvent] = useState<string>("");
    const [outcomes, setOutcomes] = useState<IOutcome[]>([])
    const [stepOutcomes, setStepOutcomes] = useState<IStepOutcomes[]>([]);
    const [stepOutcomesWithName, setStepOutcomesWithName] = useState<IStepOutcomeWithName[]>([]);

    const webHelpers = useWebHelper();
    const toast = useToast();

    const handleClickOpen = () => {
        setLoading(true);
        onOpen();
        //searchStepOutcomes();
        getStepOutcomes();
        console.log("open");
    }
    const handleClose = () => {
        onClose();
        props.cb();
    }
    const handleSearchChange = (e: any) => {
        setSearch(e.currentTarget.value);
    }
    // const searchStepOutcomes = () => {
    //     console.log("test")
    //     webHelpers.GetAsync<any>("/api/workflow/step/" + props.stepId + "/outcomes", "helios-api").then((res) => {
    //         console.log("Outcomes for this step ", res);

    //     })
    // }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getStepOutcomes = () => {
        setLoading(true);
        setStepOutcomesWithName([]);
        let arrayExisting = [];

        webHelpers.GetAsync<IStepOutcomes[]>("/api/workflow/step/" + props.stepId + "/outcomes", "helios-api").then((res) => {
            if (res !== null && res !== undefined) {
                console.log("getStepOutcomes response = ", res);
                console.log("getStepOutcomesWithName = ", stepOutcomesWithName)
                setStepOutcomes(res);
                setMaxPagesFirstTab(Math.ceil(Number((res.length / rowsPerPage))));
                let testArray: IStepOutcomeWithName[] = []
                webHelpers.GetAsync<IOutcome[]>("/api/config/outcomes", "helios-api").then((result) => {
                    console.log(result) //gets all outcomes
                    //cross reference all outcomes with outcomes attached to current step
                    // if (result !== null && result !== undefined) {
                    //     res.forEach((each) => {
                    //         let name = result.filter((x) => x.id === each.outcomeDefinitionId)[0].id;
                    //         let friendly_id = result.filter((x) => x.id === each.outcomeDefinitionId)[0].friendly_id;
                    //         if (result.filter((x) => x.id === each.outcomeDefinitionId)) {
                    //             testArray.push({ outcomeDefinitionId: each.outcomeDefinitionId, name: name, friendly_id: friendly_id })
                    //         }
                    //     })
                    //     setStepOutcomesWithName(testArray)
                    // }
                }).finally(() => {
                    setLoading(false);
                })
            }
        }).catch((error) => {
            setLoading(false);
            return (
                toast({
                    position: "bottom",
                    title: "Could fetch outcomes for this workflow step (" + error + ")",
                    status: "error",
                    isClosable: true
                })
            )
        })
    }
    const getOutcomes = () => {
        setLoading(true);

        webHelpers.GetAsync<IOutcome[]>("/api/workflow/step/" + props.stepId + "/outcomes/config/available", "helios-api").then((res) => {
            if (res !== null && res !== undefined) {
                setOutcomes(res);
                setMaxPages(Math.ceil(Number((res.length / rowsPerPage))));
                //setLoading(false);
            }
        }).catch((error) => {
            return (
                toast({
                    position: "bottom",
                    title: "Could not fetch outcomes (" + error + ")",
                    status: "error",
                    isClosable: true,
                })
            )
        })
    }
    const handleDelete = (id: string) => {
        setLoading(true)
        webHelpers.DeleteAsync<any, any>("/api/workflow/step/" + props.stepId + "/outcome/" + id, "helios-api", {}).then((res) => {
            console.log("delete step outcome reponse = ", res);
            getStepOutcomes();
            return (
                toast({
                    position: "bottom",
                    title: "Successfully removed Outcome from Workflow Step",
                    status: "success",
                    isClosable: true,
                })
            )
        }).catch((error) => {
            console.log("caught error while deleting linked outcome : ", error);
            console.log(error.response);
            getStepOutcomes();
            if (error.response === "Api returned 200, unable to parse data into type specified during call") {
                return (
                    toast({
                        position: "bottom",
                        title: "Successfully removed outcome from workflow step",
                        status: "success",
                        isClosable: true,
                    })
                )
            }
            else {
                return (
                    toast({
                        position: "bottom",
                        title: "Error deleting outcome from workflow step [" + error.status + "]",
                        status: "error",
                        isClosable: true
                    })
                )
            }

        })
    }
    const AddOutcomeToStep = (id: string, isCompletion: boolean, isQuick: boolean, isReschedule: boolean) => {
        console.log("adding outcome")
        let payload = {
            isCompletionOutcome: isCompletion,
            isQuickOutcome: isQuick,
            isRescheduleOutcome: isReschedule,
        }
        console.log(payload)
        webHelpers.PutAsync("/api/workflow/step/" + props.stepId + "/outcome/" + id, "helios-api", payload
        ).then((res) => {
            getStepOutcomes();
            console.log("add outcome to step response, ", res);
        })
    }
    useEffect(() => {
        console.log("stepOutcomes : ", stepOutcomes, "outcomes: ", outcomes)
    }, [outcomes, stepOutcomes])

    return (
        <>
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                onClick={handleClickOpen} leftIcon={<BiAddToQueue />}
            >
                Manage Outcomes
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"4xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Seeing Assigned Outcomes for Workflow Step : " + props.stepId}<br />
                        <Text as={"i"} marginBottom={"1px"} fontSize={"sm"}>Listed below are the outcomes currently assigned to this workflow step</Text></ModalHeader>
                    <ModalBody>
                        <>
                            <Tabs position="relative" variant="enclosed-colored" size={"lg"} onChange={() => {
                                getOutcomes();
                                getStepOutcomes();
                                setPage(0);
                                setLoading(true);
                            }
                            }>
                                <TabList >
                                    <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"}>Active Outcomes</Tab>
                                    <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"}>Add Outcomes</Tab>
                                </TabList>
                                <TabIndicator
                                    mt="-1.5px"
                                    height="2px"
                                    bg="AbleYellow"
                                    borderRadius="1px"
                                />
                                <TabPanels>

                                    <TabPanel>
                                        {stepOutcomes.length === 0 ?
                                            <Text textAlign={"center"} fontSize={"xl"} marginTop={"10px"}>This step currently has no outcomes attached</Text>
                                            :
                                            <>
                                                <Flex direction={"row"} justifyContent={"right"} display={loading ? "none" : "flex"}>
                                                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                                    <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                                        <option value={5} >5 </option>
                                                        <option value={10}>10</option>
                                                        <option value={15}>15</option>
                                                    </Select>
                                                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPagesFirstTab + 1} </Text>
                                                    <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                        page !== 0 && setPage(page - 1);
                                                    }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                                    <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                        page !== maxPagesFirstTab + 1 && setPage(page + 1)
                                                    }} isDisabled={page === maxPagesFirstTab}><AiFillCaretRight /></Button>
                                                </Flex>
                                                {loading ?
                                                    <Flex justifyContent={"center"}>
                                                        <Spinner color="AbleBlue" />
                                                    </Flex>
                                                    :

                                                    <TableContainer>
                                                        {/* this tab shows the linked outcomes for the current workflow step */}
                                                        <Table variant={"simple"}>
                                                            <Thead>
                                                                <Tr>
                                                                    <Th display={{ base: "none", lg: "revert" }}>Name</Th>
                                                                    <Th display={{ base: "none", lg: "revert" }}>Id</Th>
                                                                    <Th></Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {/* THIS GENERATES A RANDOM KEY */}
                                                                {stepOutcomes.map((each: IStepOutcomes) => (

                                                                    <Tr key={crypto.randomUUID()}>
                                                                        <Td>{each.name}</Td>
                                                                        <Td>{each.friendly_id}</Td>
                                                                        <Td>
                                                                            <AddOutcome stepId={props.stepId} outcomeId={each.id} mode="edit" 
                                                                            data={{is_completion_outcome: each.is_completion_outcome,
                                                                                is_quick_outcome: each.is_quick_outcome,
                                                                                is_reschedule_outcome: each.is_reschedule_outcome
                                                                            }} 
                                                                            cb={() => {
                                                                                getOutcomes();
                                                                                getStepOutcomes();
                                                                                setPage(0);
                                                                                setLoading(true)
                                                                            }} 
                                                                            />
                                                                        </Td>
                                                                        <Td><GetUserConfirm confirmFunction={() => handleDelete(each.id)} declineFunction={() => { console.log("decline") }} buttonText={"Delete"} buttonWarning="Are you sure you want to remove this outcome from the workflow step?" />
                                                                        </Td>
                                                                    </Tr>
                                                                ))}
                                                                {/* <AddSkill role={props.role} handleAddSkills={addSkills} updateSkills={fetchSkills} /> */}
                                                            </Tbody>
                                                        </Table>

                                                    </TableContainer>
                                                }
                                            </>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        <Flex direction={"row"} justifyContent={"right"}>
                                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                            <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                                <option value={5} >5 </option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                            </Select>
                                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPages} </Text>
                                            <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                page !== 0 && setPage(page - 1);
                                            }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                            <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                page !== maxPages - 1 && setPage(page + 1)
                                            }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                                        </Flex>
                                        {/* need to have a section or method of showing already selected skills */}
                                        <TableContainer>
                                            <Table variant={"simple"}>
                                                <Thead>
                                                    <Tr>
                                                        {/* this will show all skill that are possible to see */}
                                                        <Th>Friendly Id</Th>
                                                        <Th>Name</Th>
                                                        <Th>Description</Th>
                                                        {/* <Th display={{ base: "none", lg: "flex" }}>Skill Level</Th> */}
                                                        <Th></Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {outcomes.map((outcome: IOutcome) =>

                                                        <Tr key={outcome.id}>
                                                            <Td>{outcome.friendly_id}</Td>
                                                            <Td>{outcome.name}</Td>
                                                            <Td>{outcome.description}</Td>
                                                            <Td>

                                                                <AddOutcome stepId={props.stepId} outcomeId={outcome.id} mode="add" data={{is_completion_outcome: false, is_quick_outcome: false, is_reschedule_outcome: false}} cb={() => {
                                                                    getOutcomes();
                                                                    getStepOutcomes();
                                                                    setPage(0);
                                                                    setLoading(true)
                                                                }} />

                                                            </Td>

                                                        </Tr>
                                                    )}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent={"space-between"}>
                            <Button bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                                onClick={handleClose} >Finish</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
